<template>
  <div>
    <v-container fluid>

      <v-overlay v-model="overlay" opacity="0.3" color="white" z-index="99999">
        <v-progress-circular color="primary" indeterminate size="55"></v-progress-circular>
      </v-overlay>

      <v-row justify="center">

        <v-col cols="12" lg="10">
          <v-card class="transparent mt-5" flat>

            <form @submit.prevent="searchPos">
              <div class="d-flex">
                <v-text-field v-model.trim="keyword"
                              :counter="10"
                              persistent-hint
                              :error-messages="errors.keyword"
                              :background-color="$vuetify.theme.dark ? 'dark' : 'white'"
                              class="search-input flex-column flex"
                              filled
                              clearable
                              placeholder="Entrez le numéro ou le code POS ici..."
                              prepend-inner-icon="mdi-magnify"
                              required
                              rounded
                              @input="errors.keyword = ''"
                >
                </v-text-field>
                <v-btn class="btn-search"
                       color="primary"
                       depressed
                       height="56"
                       type="submit">
                  Recherche
                </v-btn>
              </div>
            </form>

            <v-card v-if="Object.keys(salepoint).length" class="shadow rounded-lg">
              <v-card-text class="pa-6 pa-lg-10">

                <v-simple-table class="table-border">
                  <template v-slot:default>
                    <tbody>
                    <tr :class="$vuetify.theme.dark ?'fullscreen' : 'grey lighten-3'">
                      <td class="font-weight-bold text-primary text-no-wrap py-5" colspan="2">
                        Information du point de vente
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon left>mdi-code-array</v-icon>
                        Code du pos
                      </td>
                      <td class="primary--text font-weight-medium">{{ salepoint.salepoint_code }}</td>
                    </tr>

                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon left>mdi-phone</v-icon>
                        N° de téléphone
                      </td>
                      <td>{{ salepoint.salepoint_phone }}</td>
                    </tr>

                    </tbody>
                  </template>
                </v-simple-table>

                <div class="mt-5" v-if="!salepoint.gifty_id && !Object.keys(giftyAccount).length">
                  <v-text-field dense
                                counter="10"
                                required
                                outlined
                                :error-messages="phoneError"
                                prepend-icon="mdi-phone-outline"
                                v-model="salepoint.salepoint_phone"
                                label="Le numéro de compte GIFTY">
                  </v-text-field>
                  <v-btn
                      block
                      :disabled="!salepoint || !salepoint.salepoint_phone || salepoint.salepoint_phone.length !== 10"
                      @click="getGiftyAccountByNumber(salepoint.salepoint_phone)"
                      color="info"
                      depressed
                  >
                    Compte GIFTY
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-btn>
                </div>

                <v-alert v-if="salepoint.gifty_id"
                         dark
                         dense
                         border="left"
                         class="mt-5 fs-15"
                         color="info"
                         icon="mdi-check"
                         prominent>
                  Ce point de vente est déjà lié à Gifty.
                </v-alert>

                <v-simple-table v-if="salepoint.gifty_id || Object.keys(giftyAccount).length"
                                class="table-border mt-3">
                  <template v-slot:default>
                    <tbody>
                    <tr :class="$vuetify.theme.dark ?'fullscreen' : 'grey lighten-3'">
                      <td class="font-weight-bold text-primary text-no-wrap py-5" colspan="2">
                        Information du compte GIFTY
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">
                        <v-icon left>mdi-account</v-icon>
                        Nom complet
                      </td>
                      <td>{{ giftyAccount.name }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium text-no-wrap">
                        <v-icon left>mdi-phone</v-icon>
                        N° de téléphone
                      </td>
                      <td>{{ giftyAccount.phone }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <div class="mt-5" v-if="!salepoint.gifty_id && Object.keys(giftyAccount).length">
                  <v-btn block
                         @click="confirmLink"
                         color="success"
                         depressed>
                    <v-icon left>mdi-check</v-icon>
                    Lié avec Gifty
                  </v-btn>
                </div>


              </v-card-text>
            </v-card>


            <v-alert v-if="noPos"
                     border="left"
                     class="mb-0 fs-15 fade-in"
                     text
                     dense
                     dismissible
                     prominent
                     type="warning">
              <p class="mb-0">OOPS ! Aucun résultat trouvé. Veuillez saisir un numéro ou un code POS valide.</p>
            </v-alert>

            <v-alert v-if="success"
                     border="left"
                     class="mb-0 fs-15 fade-in"
                     dense
                     text
                     dismissible
                     prominent
                     type="success">
              <p class="mb-0">POS lié avec succès à Gifty.</p>
            </v-alert>


            <v-card-text v-if="!Object.keys(salepoint).length"
                         class="text-center">

              <div class="text-center">
                <img :src="require('@/assets/search.svg')" alt="" width="250"/>
              </div>

              <h1 :class="!$vuetify.breakpoint.mobile || 'fs-22'" class="my-4">
                La laison avec GIFTY.
              </h1>

              <p>
                Liez vos points de vente avec l'application GIFTY pour une gestion simplifiée <br>
                et un suivi en temps réel de vos opérations commerciales.
              </p>

            </v-card-text>

          </v-card>

        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import axios from "axios";

export default {
  data() {
    return {
      success: false,
      overlay: false,
      noPos: false,
      keyword: '',
      salepoint: {},
      giftyAccount: {},
      errors: {},
      phone: '',
      phoneError: null
    }
  },
  methods: {
    confirmLink() {
      this.overlay = true
      HTTP.post('/database/link-pos', {
        salepoint: this.salepoint,
        gifty_account: this.giftyAccount,
      }).then(() => {
        this.overlay = false
        this.success = true;
        this.errors = {};
        this.salepoint = {};
        this.giftyAccount = {};
        this.keyword = '';
        this.phoneError = '';
        this.noPos = false;
        this.$vuetify.goTo(0)

        let _vm = this
        setTimeout(() => {
          _vm.success = false;
        }, 2000);

      }).catch(err => {
        this.overlay = false
        this.$errorMessage = "Une erreur s'est produite !";
        console.log(err)
      })
    },
    searchPos() {
      this.errors = {}
      this.salepoint = {}
      this.giftyAccount = {}
      this.overlay = true
      this.noPos = false
      HTTP.get('/database/search-pos', {
        params: {
          keyword: this.keyword
        }
      }).then((res) => {

        this.overlay = false

        if (res.data.data) {
          this.salepoint = res.data.data
          /**
           * Get Gifty Account
           */
          if (this.salepoint.gifty_id) {
            this.getGiftyAccount(this.salepoint.gifty_id)
          }
        } else {
          this.noPos = true
        }
      }).catch(err => {
        this.overlay = false
        this.errors = err.response.data.errors
        console.log(err)
      })
    },
    getGiftyAccount(giftyId) {
      this.overlay = true
      axios.get("https://api.gifty.opus.win" + '/api/account/' + giftyId).then((res) => {
        this.giftyAccount = res.data.user
        this.overlay = false
      }).catch(err => {
        console.log(err)
        this.overlay = false
      })
    },
    getGiftyAccountByNumber(phone) {
      this.phoneError = ''
      if (phone) {
        this.overlay = true
        let phone1 = phone.replace(/^0/, '+213');
        axios.get("https://api.gifty.opus.win/api/user/phone/" + phone1).then((res) => {
          this.giftyAccount = res.data
          this.overlay = false

          if (this.giftyAccount || !res) {
            this.phoneError = "Ce numéro n'existe pas dans le système GIFTY.";
          }

        }).catch(err => {
          console.log(err)
          this.overlay = false
        })
      }
    },
  }
}
</script>

<style>
.btn-search {
  border-radius: 0 28px 28px 0;
}

.search-input v-text-field .v-input__control, .search-input.v-text-field .v-input__slot, .search-input.v-text-field fieldset {
  border-radius: 28px 0 0 28px;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>